@import '../../../../styles/customMediaQueries.css';

.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.landingHero {
  height: 100vh;
  display: flex;

  @media (--viewportMedium) {
    height: 55vh;
  }

  & div:first-child {
    text-align: right;

    /* & img {
      width: unset;
      object-fit: contain;
    } */
  }

  & >div:last-child {
    background: var(--camoColorMedium);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    & > div {
      @media (--viewportMedium) {
        width: 50%;
      }
    }

    & > div:last-child {
      width: 85%;

      @media (--viewportMedium) {
        width: 40%;
        margin-right: 2em;
      }
    }

    @media (--viewportMedium) {
      flex-direction: row;
      background: linear-gradient(90deg, var(--camoColorLow), transparent);
    }
  }
}

.heroHeader {
  justify-content: center !important;
  margin: 0 10vw !important;
  width: 80vw;

  & h1, & p {
    text-align: center !important;
  }

  & p {
    justify-self: center !important;
  }

  @media (--viewportLarge) {
    justify-content: start !important;
    width: 50vw;

    & h1, & p {
      text-align: left !important;
    }

    & p {
      justify-self: unset !important;
    }
  }
}

.heroDescription {
  justify-self: left !important;
  text-align: left !important;
}

.landingTitle {
  font-family: "El Messiri", sans-serif;
  text-align: left !important;
}

.landingContainer {
  margin: 2em 10vw !important;
  padding: 0 32px !important;
}
